import { createRouter, createWebHistory } from 'vue-router'
import store from './store'
// import Home from './views/Home.vue'
import Login from './views/login/Login.vue'

import HomeTotal from './views/HomeTotal.vue'
import AdminUsers from './views/admin/AdminUsers.vue'
import AdminHunts from './views/admin/Hunts.vue'
import AdminPromos from './views/admin/Promos.vue'
import AdminDashboard from './views/admin/AdminDashboard.vue'
import Compensation from './views/admin/Compensation.vue'
import Products from './views/admin/Products.vue'
import MyProducts from './views/client/Products.vue'

import DenyInvite from './views/DenyInvite.vue'
import DefinePassword from './views/DefinePassword.vue'
import RecoverPassword from './views/RecoverPassword.vue'
import RecoverAccount from './views/RecoverAccount.vue'
import Dashboard from '@/views/client/Dashboard.vue'
import Orders from './views/client/Orders.vue'
import Hunts from './views/client/Hunts.vue'
import ChangeData from './views/ChangeData.vue'

const routes = [
  { path: '/', component: HomeTotal },
  { path: '/data', component: ChangeData, meta: { title: 'Manipulador de dados' } },
  { path: '/denyInvite', component: DenyInvite, meta: { title: 'Negar convite' } },
  { path: '/definePassword', component: DefinePassword, meta: { title: 'Definir senha' } },
  { path: '/recoverPassword', component: RecoverPassword, meta: { title: 'Redefinir senha' } },
  { path: '/recoverAccount', component: RecoverAccount, meta: { title: 'Recupere sua conta' } },
  { path: '/login', component: Login, meta: { title: 'Login' } },
  { path: '/admin/dashboard', component: AdminDashboard, meta: { title: 'Painel do administrador', requiresAuth: true, requiredRoles: ['admin'] } },
  { path: '/admin/users', component: AdminUsers, meta: { title: 'Usuários', requiresAuth: true, requiredRoles: ['admin'] } },
  { path: '/admin/hunts', component: AdminHunts, meta: { title: 'Cashbacks', requiresAuth: true, requiredRoles: ['admin'] } },
  { path: '/admin/promos', component: AdminPromos, meta: { title: 'Promoções', requiresAuth: true, requiredRoles: ['admin'] } },
  { path: '/admin/compensation', component: Compensation, meta: { title: 'Compensação', requiresAuth: true, requiredRoles: ['admin', 'logistics'] } },
  { path: '/admin/products', component: Products, meta: { title: 'Produtos', requiresAuth: true, requiredRoles: ['admin'] } },
  { path: '/dashboard', component: Dashboard, meta: { title: 'Dashboard', requiresAuth: true, requiredRoles: ['client'] } },
  { path: '/orders', component: Orders, meta: { title: 'Pedidos', requiresAuth: true, requiredRoles: ['client'] } },
  { path: '/products', component: MyProducts, meta: { title: 'Produtos', requiresAuth: true, requiredRoles: ['client'] } },
  { path: '/hunts', component: Hunts, meta: { title: 'Cashback', requiresAuth: true, requiredRoles: ['client'] } }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Antares | ${to.meta.title || ''}`
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/login')
    } else if (to.matched.some(record => record.meta.requiredRoles)) {
      const userRole = store.state.user_access
      if (!to.meta.requiredRoles.includes(userRole)) {
        next('/')
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
