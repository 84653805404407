<template>
  <div class="content codes-content">
    <UserHeader />
    <div class="search">
      <div class="search-input">
        <input type="text" placeholder="Digite sua busca" v-model="searchQuery" @keyup.enter="search">
      </div>
      <button class="main-btn create-code" @click="toggleAddingClass">
        <p>Criar novo</p>
        <p class="adding">Cancelar</p>
        <div></div>
      </button>
    </div>
    <form @submit.prevent="submitCode" class="newCode">
      <div class="newCode_columm">
        <label>Produto Antares</label>
        <ItemsList @item-selected="addItemToOrder" ref="ItemsList"/>
        <input v-model="formData.item_id" readonly="readonly" type="hidden" id="item_id" required>
      </div>
      <div class="newCode_columm">
        <label>Meu código para o produto</label>
        <input v-model="formData.code" type="text" id="code" required>
      </div>
      <div class="newCode_columm">
        <label>Meu nome para o produto</label>
        <input v-model="formData.name" type="text" id="name" required>
      </div>
      <div class="newCode_columm btn">
        <button type="submit" class="main-btn">Adicionar</button>
      </div>
    </form>
    <div class="table-main table-list" v-if="codes.length > 0">
      <table class="table-separate">
        <tr>
          <!--
          <th>
            <p>ID</p>
          </th>
          -->
          <th>
            <p>Produto Antares</p>
          </th>
          <th>
            <p>Meu código para o produto</p>
          </th>
          <th>
            <p>Meu nome para o produto</p>
          </th>
          <th>
            <p>Ações</p>
          </th>
        </tr>
        <tr v-for="code in codes" v-bind:key="code.id">
          <!--
          <td>
            <p>
              {{ code.id }}
            </p>
          </td>
          -->
          <td>
            <p>
              {{ code.relationships.item.attributes.name }} ({{ code.relationships.item.attributes.code }})
            </p>
          </td>
          <td>
            <p>
              {{ code.attributes.code }}
            </p>
          </td>
          <td>
            <p>
              {{ code.attributes.name }}
            </p>
          </td>
          <td>
            <button @click="removeCode(code.id)" class="deleteCode">
              Apagar
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-main table-list no-codes" v-else >
      Nenhum código para exibir.
    </div>
    <Footer />
  </div>
  <router-view />
</template>

<script>
import axios from '../../config/axiosConfig.js'
import { mapActions, mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import UserHeader from '@/components/client/UserHeader.vue'
import ItemsList from '@/components/ItemsList.vue'

export default {
  components: {
    UserHeader,
    Footer,
    ItemsList
  },
  data () {
    return {
      formData: {
        item_id: '',
        code: '',
        // UserId: '',
        selectedItems: null,
        selectedOption: '',
        searchQuery: '',
        name: ''
      },
      isAdding: false
    }
  },
  computed: {
    ...mapGetters(['orders', 'codes', 'hunts', 'user', 'items', 'total_pages'])
  },
  methods: {
    ...mapActions(['logout', 'fetchItemsCodes', 'fetchUser', 'setLoadingState']),
    async removeCode (userItemId) {
      // const UserId = this.user.id
      console.log('Clicou em deletar')
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/items/codes/${userItemId}`
        const body = { }
        const response = await axios.delete(url, body)
        if (response.status === 200) {
          this.formData.item_id = ''
          this.formData.code = ''
          this.$refs.ItemsList.clearSelection()
          await this.$store.dispatch('fetchItemsCodes', { userId: this.user.id })
          this.$notification.emit('flash', { message: 'Código deletado com sucesso', type: 'success' })
        } else {
          this.$notification.emit('flash', { message: 'Erro ao deletar o código', type: 'error' })
        }
      } catch (error) {
        console.error('Erro na requisição:', error)
      }
    },
    search () {
      console.log('Search Query:', this.searchQuery)
      this.$store.dispatch('fetchItemsCodes', { userId: this.user.id, filterName: this.searchQuery })
    },
    toggleAddingClass () {
      this.isAdding = !this.isAdding
      document.body.classList.toggle('adding', this.isAdding)
      this.formData.item_id = ''
      this.formData.code = ''
      this.formData.name = ''
      this.$refs.ItemsList.clearSelection()
    },
    async submitCode () {
      const UserId = this.user.id
      this.formData.user_id = UserId
      delete this.formData.searchQuery
      delete this.formData.selectedItems
      delete this.formData.selectedOption
      // const codeUserId = this.user.id
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/items/codes`

        const response = await axios.post(url, this.formData)
        if (response.status === 201) {
          this.formData.item_id = ''
          this.formData.code = ''
          this.formData.name = ''
          this.$refs.ItemsList.clearSelection()
          await this.$store.dispatch('fetchItemsCodes', { userId: this.user.id })
          this.$notification.emit('flash', { message: 'Código adicionado com sucesso', type: 'success' })
          // Faça algo com a resposta, se necessário
        } else {
          this.$notification.emit('flash', { message: 'Erro ao adicionar o código', type: 'error' })
        }
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao adicionar o código', type: 'error' })
        // Trate o erro conforme necessário
      }
    },
    addItemToOrder (item) {
      this.formData.item_id = item.relationships.item.id
    },
    formatOrderItems (items) {
      items.forEach(item => {
        try {
          const response = axios.get(`${process.env.VUE_APP_API_URL}/api/my/items`, {
            params: {
              'filter[id]': item.relationships.item.id
            }
          })
          this.items.push(response.data.data)
        } catch (error) {
          console.log(error)
        } finally {

        }
      })

      return items.map(item => {
        const storeItem = this.items.find(storeItem => storeItem.relationships.item.id === item.relationships.item.id)
        return {
          id: item.relationships.item.id,
          name: item.relationships.item.attributes.name,
          can_be_added: item.attributes.enable_discount,
          quantity: item.attributes.quantity,
          hunt: item.relationships.item.attributes.hunt,
          delivery_at: item.attributes.deliveryAt,
          unit_original_price: storeItem.attributes.amount * (1 - this.user.attributes.discounts.main),
          unit_discount_price: (storeItem.attributes.amount * (1 - this.user.attributes.discounts.main)) * (1 - Number(this.user.attributes.discounts.current))
        }
      })
    }
  },
  async created () {
    await this.fetchUser()
    await this.$store.dispatch('fetchItemsCodes', { userId: this.user.id })
  }
}
</script>
