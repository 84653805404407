<template>
  <div class="table-main dash-table dash-hunts">
    <h2>Notas</h2>
    <div class="balance-discount admin">
                            <div class="bd-block pending">
                                <div class="status-bd">
                                    <div></div>
                                    <p>{{pendingInvoices}}</p>
                                </div>
                                <h3>Notas pendentes</h3>
                            </div>
                            <!-- <div class="bd-block pending">
                                <div class="status-bd">
                                    <div></div>
                                    <p>---</p>
                                </div>
                                <h3>Cashbacks pendentes</h3>
                            </div> -->
                            <div class="bd-block approved">
                                <div class="status-bd">
                                    <div></div>
                                    <p>{{approvedInvoices}}</p>
                                </div>
                                <h3>Notas aprovadas</h3>
                                <span></span>
                            </div>
                            <div class="bd-block recused">
                                <div class="status-bd">
                                    <div></div>
                                    <p>{{rejectedInvoices}}</p>
                                </div>
                                <h3>Notas recusadas</h3>
                                <span></span>
                            </div>
                        </div>

</div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      pendingInvoices: '',
      rejectedInvoices: '',
      approvedInvoices: ''
    }
  },
  computed: {
    ...mapGetters(['users'])
  },
  methods: {
    ...mapActions(['fetchStatements', 'setLoadingState']),
    async fetchBigNumbers () {
      this.setLoadingState(true)
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/bignumbers`)
        this.pendingInvoices = response.data.data.attributes.invoices.pending
        this.rejectedInvoices = response.data.data.attributes.invoices.rejected
        this.approvedInvoices = response.data.data.attributes.invoices.approved
      } catch (error) {

      } finally {
        this.setLoadingState(false)
      }
    }
  },
  created () {
    this.fetchBigNumbers()
    // this.fetchStatements()
  }
}
</script>
