<template>
  <div class="content users-content">
    <AdminHeader />
    <div class="search">
      <div class="search-input">
        <input type="text" placeholder="Digite sua busca" v-model="searchQuery" @keyup.enter="search">
      </div>
      <button class="main-btn create-user" @click="showModalCreateUser = true; addBodyClass()">
        <p>Criar novo</p>
        <div></div>
      </button>
    </div>
    <div class="table-main table-list">
      <table class="table-separate">
        <tr>
          <!--
          <th>
            <input type="checkbox" name="order-check" id="order-check" class="check-all">
          </th>
          -->
          <th>
            <p>Status</p>
          </th>
          <th @click="sort('name')">
            <p>Usuário</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('role')">
            <p>Cargo</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('last-login-at')">
            <p>Último login</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <!--
          <td>
            <input type="checkbox" name="order-check" id="order-check" class="check-one">
          </td>
          -->
          <td>
            <div class="status-wrap" :class="user.attributes.status">
              <div class="status">
                {{ user.attributes.status === 'active' ? 'Ativo' : user.attributes.status === 'inactive' ? 'Inativo' : user.attributes.status === 'blocked' ? 'Bloqueado' : '' }}
              </div>
            </div>
          </td>
          <td class="link-over">
            <a @click="openUserDetails(user)">
              {{ user.attributes.name }}
              <span>
                <!-- {{user.id}} -->
                {{ user.attributes.email }}
              </span>
            </a>
          </td>
          <td>
            <p v-if="user.attributes.role === 'client'">
              Distribuidor
            </p>
            <p v-if="user.attributes.role === 'meeg'">
              Super Administrador
            </p>
            <p v-if="user.attributes.role === 'admin'">
              Administrador
            </p>
            <p v-if="user.attributes.role === 'logistics'">
              Logística
            </p>
          </td>
          <td>
            <p v-if="$formattedDateWithoutHours(user.attributes.lastLoginAt) !== '31/12/1969'">
              {{ $formattedDateWithoutHours(user.attributes.lastLoginAt) }}
            </p>
            <p v-else>Nunca logou</p>
            <span v-if="$formattedDateOnlyHours(user.attributes.lastLoginAt) !== '21:00'">
              {{ $formattedDateOnlyHours(user.attributes.lastLoginAt) }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>
  <ModalUserEdit :show="showModalUserEdit" @close="showModalUserEdit = false" :user_data="selectedUser" :currentPage="currentPage" />
  <ModalCreateUser :show="showModalCreateUser" @close="showModalCreateUser = false" @submit="createOrder" />
  <ModalUserDetail :show="showUserDetailsModal" :user_data="selectedUser" @close="showUserDetailsModal = false"
    @edit="openUserEditFromDetails" />
</template>
<script>
import AdminHeader from '../../components/admin/AdminHeader.vue'
import ModalUserEdit from '@/components/admin/ModalUserEdit.vue'
import ModalCreateUser from '@/components/admin/ModalCreateUser.vue'
import ModalUserDetail from '@/components/admin/ModalUserDetail'
import Footer from '@/components/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      currentPage: 1,
      searchQuery: '',
      sortColumn: null,
      sortDirection: 'asc',
      showModalCreateUser: false,
      showUserDetailsModal: false,
      showModalUserEdit: false,
      selectedUser: null
    }
  },
  components: {
    AdminHeader,
    Footer,
    ModalCreateUser,
    ModalUserDetail,
    ModalUserEdit
  },

  computed: {
    ...mapGetters(['orders', 'hunts', 'users', 'total_pages'])
  },
  methods: {
    ...mapActions(['fetchUser', 'adminFetchUsers', 'fetchUser']),
    changePage (page) {
      this.currentPage = page
      this.adminFetchUsers({ page: this.currentPage, page_size: 10 })
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    },
    search () {
      console.log('Search Query:', this.searchQuery)
      this.$store.dispatch('adminFetchUsers', { filterName: this.searchQuery })
    },
    openUserDetails (user) {
      console.log('Método openUserDetails chamado.')
      this.selectedUser = user
      console.log(this.selectedUser)
      this.showUserDetailsModal = true
      document.body.classList.add('modal-opened')
    },
    openUserEdit (user) {
      this.selectedUser = user
      this.showModalUserEdit = true
      document.body.classList.add('modal-opened')
    },
    openUserEditFromDetails () {
      this.showUserDetailsModal = false
      this.showModalUserEdit = true
      document.body.classList.add('modal-opened')
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.adminFetchUsers({ page: this.currentPage, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    }
  },
  created () {
    this.fetchUser()
    this.adminFetchUsers({ page: this.currentPage, page_size: 10, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
  }

}

</script>
