<template>
  <div class="side-menu" v-if="isLoggedIn">
    <div class="logo">
      <div></div>
    </div>
    <div class="nav-menu">
      <nav>
        <!-- menu para usuario normal. Depois tem que fazer a mesma coisa pra admin -->
        <ul v-if="userRole === 'client'">
          <li :class="{ active: $route.path === '/dashboard' }" class="dash">
            <router-link to="/dashboard">
              <div></div>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/orders' }" class="orders">
            <router-link to="/orders">
              <div></div>
              <p>Pedidos</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/hunts' }" class="cacadas">
            <router-link to="/hunts">
              <div></div>
              <p>Cashback</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/products' }" class="products">
            <router-link to="/products">
              <div></div>
              <p>Produtos</p>
            </router-link>
          </li>
        </ul>

        <ul v-if="userRole === 'admin'">
          <li :class="{ active: $route.path === '/admin/dashboard' }" class="dash">
            <router-link to="/admin/dashboard">
              <div></div>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/admin/users' }" class="users">
            <router-link to="/admin/users">
              <div></div>
              <p>Usuários</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/admin/hunts' }" class="cacadas">
            <router-link to="/admin/hunts">
              <div></div>
              <p>Cashbacks</p>
            </router-link>
          </li>
          <!--
          <li :class="{ active: $route.path === '/admin/promos' }" class="promos">
            <router-link to="/admin/promos">
              <div></div>
              <p>Promoções</p>
            </router-link>
          </li>
          -->
          <li :class="{ active: $route.path === '/admin/compensation' }" class="balance">
            <router-link to="/admin/compensation">
              <div></div>
              <p>Compensação</p>
            </router-link>
          </li>
          <li :class="{ active: $route.path === '/admin/products' }" class="products">
            <router-link to="/admin/products">
              <div></div>
              <p>Produtos</p>
            </router-link>
          </li>
        </ul>
        <ul v-if="userRole === 'logistics'">
          <li :class="{ active: $route.path === '/admin/compensation' }" class="balance">
            <router-link to="/admin/compensation">
              <div></div>
              <p>Compensação</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="hide-menu" @click="toggleHide">
      <button class="small-btn"></button>
      <p>Esconder</p>
    </div>
  </div>
</template>
<script>
import { computed, watch, ref } from 'vue'
import { mapGetters, useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()

    const userRole = computed(() => store.state.user_access)
    const key = ref(0)

    watch(userRole, () => {
      // Incrementa a chave para forçar a re-renderização do componente
      key.value++
    })

    return { userRole, key }
  },
  computed: {
    ...mapGetters(['user', 'isLoggedIn'])
  },
  methods: {
    toggleHide () {
      this.hideMenu = !this.hideMenu
      if (this.hideMenu) {
        document.body.classList.add('menu-closed')
      } else {
        document.body.classList.remove('menu-closed')
      }
    }
  }
}
</script>
