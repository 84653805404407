<template>
  <div v-if="show" class="modal">
    <div class="modal-content popup-detail edit-order data form">
      <span class="close small-btn" @click="closeModal">&times;</span>
      <div class="title">
        <h1>Criar pedido</h1>
      </div>

      <div class="order-data-form">
      <form @submit.prevent="createOrder">
         <div class="input-flex">
            <input type="hidden" :value="order.store_id = user.relationships.stores.data[0].id">
            <div class="input-wrap half-big">
              <label for="description">Descrição</label>
              <input v-model="order.description" type="text" placeholder="Digite aqui"  />
            </div>
        </div>
        <div class="input-wrap">
          <label for="description">Itens</label>
          <ItemsMultiSelect @item-selected="addItemToOrder" />
        </div>
        <div class="card">
          <div class="order-items">
            <h1>Itens</h1>
            <div class="itens-table">
            <table>
              <tr>
                <th><p>Qtd</p></th>
                <th><p>Itens</p></th>
                <th><p>Preço</p></th>
                <th><p>Entrega</p></th>
              </tr>
              <tr v-for="item in order.items" :key="item.id">
                <!--
                <td>
                  <div :class="{ 'round-btn': true, 'less': true, 'disabled': item.quantity <= 1 }" @click="decrementItemQuantity(item)"></div>
                  <p>{{item.quantity}}</p>
                  <div :class="{ 'round-btn': true, 'more': true, 'disabled': !item.can_be_added }" @click="incrementItemQuantity(item)"></div>
                </td>
                -->
                <td>
                  <div class="round-btn less" @click="decrementItemQuantity(item)"></div>
                  <p>{{item.quantity}}</p>
                  <div class="round-btn more" @click="incrementItemQuantity(item)"></div>
                </td>
                <td>
                  <button class="round-btn delete" @click="removeItem(item)"></button>
                  <p>
                    {{ item.name }}
                    <strong class="hunt-true" v-if="item.hunt">Cashback</strong>
                  </p>
                </td>
                <td>
                  <div class="tooltip-text-wrapper price">
                    <!-- <p v-if="item.hunt"><s>R$ {{ $formatCentsToCurrency(item.unit_original_price) }}</s></p>
                    <p>R$ {{ $formatCentsToCurrency(item.unit_discount_price) }}</p> -->
                    <!-- <p v-if="item.hunt"><s>R$ {{ $formatCentsToCurrency(item.attributes.originalAmount) }}</s></p> -->
                    <p>R$ {{ $formatCentsToCurrency((item.attributes.amount * item.quantity))  }}<span class="price-sum">R$ {{ $formatCentsToCurrency(item.attributes.amount) }}/un.</span></p>
                    <!--<div class="tooltip">
                      <span class="tooltip-text">Seus descontos disponíveis já são aplicados aqui.
                        <div class="arrow"></div>
                      </span>
                    </div>-->
                  </div>
                </td>
                <td class="arrive-date">
                  <input type="date" :min="getMinDate()" v-model="item.delivery_at" />
                </td>
              </tr>

            </table>
          </div>
        </div>

        <div class="payment-detail">
          <div class="discount-wrap">
            <div class="used-value">
              <div class="value-wrap">
                <p>R$ {{ $formatCentsToCurrency(order.used_balance) }}</p>
                <span>utilizado de R$ {{ $formatCentsToCurrency(user.attributes.availableBalance) }}</span>
              </div>
            </div>
            <div class="current-discount">
                <p>Desconto atual</p>
                <span>{{ Math.round(user.attributes.discounts.current * 100) }}% OFF</span>
            </div>
          </div>
          <div class="total-wrap">

            <div class="total sub">
              <p>Valor dos itens</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(calculateOrderAmount()) }}</h3>
              </div>
            </div>
            <div class="total sub discount">
              <p>Desconto do cashback</p>
              <div>
                <h3>- R$ {{ $formatCentsToCurrency(order.used_balance) }}</h3>
              </div>
            </div>
            <div class="total">
              <p>Total</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(calculateOrderAmountWithDiscount()) }}</h3>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="popup-buttons">
          <button type="submit" class="main-btn checkout-order"  :class="{ 'disable': order.items.length == 0 }" @click="order.status = 'requested'">
              Enviar pedido à Antares
          </button>
          <!--<button type="submit" class="main-btn checkout-order" @click="order.status = 'requested'" :class="{ 'disable': order.items.length == 0 }">Enviar pedido à Antares</button>-->
          <button type="submit" class="main-link save-sketch" :class="{ 'disable': order.items.length == 0 }" href="" @click="order.status = 'draft'">
            Salvar pedido
          </button>
        </div>
      </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ItemsMultiSelect from '@/components/ItemsMultiselect.vue'
import axios from '@/config/axiosConfig.js'

export default {
  props: ['show'],
  data () {
    return {
      selectedOption: null,
      blockDiscounts: false,
      order: {
        // code: '',
        // status: '',
        // payment_method: '',
        // store: '',
        items: [],
        used_balance: 0,
        previous_balance: 0
      }
    }
  },
  watch: {
    'order.items': {
      handler () {
        this.calculateOrderUsedBalance()
      },
      deep: true
    }
  },
  components: {
    ItemsMultiSelect

  },
  computed: {
    ...mapGetters(['items', 'user'])
  },
  methods: {
    ...mapActions(['fetchItems', 'fetchUser', 'fetchOrders', 'setLoadingState']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    addBusinessDays (startDate, numDays) {
      const result = new Date(startDate.getTime())
      let businessDaysRemaining = numDays
      while (businessDaysRemaining > 0) {
        // Adiciona um dia à data atual
        result.setDate(result.getDate() + 1)
        // Se o dia atual for de segunda a sexta-feira, decrementa os dias úteis restantes
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          businessDaysRemaining--
        }
      }
      return result
    },
    getMinDate () {
      const today = new Date()
      const minDate = this.addBusinessDays(today, 7) // Usando a função addBusinessDays definida anteriormente
      const year = minDate.getFullYear()
      const month = (minDate.getMonth() + 1).toString().padStart(2, '0')
      const day = minDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    addItemToOrder (originalItem) {
      const item = { ...originalItem }

      item.id = item.relationships.item.id
      item.can_be_added = true
      item.quantity = 1
      item.hunt = item.relationships.item.attributes.hunt
      // item.delivery_at = new Date().toISOString().substr(0, 10)
      item.delivery_at = this.getMinDate()
      item.name = item.relationships.item.attributes.name

      item.unit_original_price = item.attributes.originalAmount
      item.unit_amount = item.hunt ? item.attributes.amount : item.attributes.originalAmount
      item.price_id = item.relationships.list.id

      const discountPrice = this.calculateItemUnitDiscountPrice(item)
      item.unit_discount_price = discountPrice
      item.subtotal = discountPrice
      item.discount_amount = item.unit_amount - item.unit_discount_price

      // Atualiza o saldo usado apenas se o item adicionado tiver cashback
      // this.order.used_balance += item.discount_amount
      if (item.hunt) {
        this.order.used_balance += item.discount_amount
      }

      // Aqui quando o item adicionado estoura o limite de desconto do usuário
      if (this.order.used_balance > this.user.attributes.availableBalance) {
        item.can_be_added = false
        this.blockDiscounts = true
        item.unit_discount_price = item.unit_amount
        this.order.items.map(item => { item.can_be_added = false })
      }
      this.order.items.push(item)
      console.log('Items', this.order.items)
    },
    removeItem (item) {
      const index = this.order.items.indexOf(item)
      if (index !== -1) {
        this.order.items.splice(index, 1)
      }
    },
    incrementItemQuantity (item) {
      item.quantity++
    },
    decrementItemQuantity (item) {
      if (item.quantity > 1) {
        item.can_be_added = true
        item.quantity--
      }
    },
    calculateItemUnitDiscountPrice (item) {
      if (item.hunt) {
        return Math.round(item.unit_amount * (1 - Number(this.user.attributes.discounts.current)))
      }
      return Math.round(item.attributes.originalAmount)
    },
    calculateOrderUsedBalance () {
      const onlyHuntItems = this.order.items.filter(item => item.hunt === true)

      const totalUsedBalance = onlyHuntItems.reduce((total, item) => {
        const unitDiscount = item.attributes.amount * this.user.attributes.discounts.current
        return Math.round(total + unitDiscount * item.quantity)
      }, 0)

      const availableBalance = this.user.attributes.availableBalance

      if (totalUsedBalance > availableBalance) {
        this.order.used_balance = availableBalance
      } else {
        this.order.used_balance = totalUsedBalance
      }
    },
    calculateOrderAmount () {
      return this.order.items.reduce((total, item) => {
        const price = item.unit_amount
        return total + price * item.quantity
      }, 0)
    },
    calculateOrderAmountWithDiscount () {
      const orderAmount = this.calculateOrderAmount()
      return orderAmount - this.order.used_balance
    },
    async createOrder () {
      try {
        this.setLoadingState(true)
        const { order } = this
        order.previous_balance = this.user.attributes.availableBalance
        // order.balance = Math.round(order.used_balance * -1)
        order.used_balance = Math.round(order.used_balance * -1)
        order.debit = Math.round(order.used_balance * -1)
        order.discount = this.calculateOrderAmount() > 0 ? (Math.abs(order.used_balance / this.calculateOrderAmount()) * 100 / 100).toFixed(2) : '0.00'
        const formData = new FormData()
        for (const key in order) {
          formData.append(key, order[key])
        }
        console.table(order.items)
        await axios
          .post(`${process.env.VUE_APP_API_URL}/api/my/orders`, formData)
          .then((resp) => {
            this.updateOrderAddItems(resp.data.data)
            // this.order.payment_method = ''
            this.order.items = []
            // this.order.code = ''
            this.order.description = ''
            this.fetchUser()
          })
          .catch((error) => {
            this.$notification.emit('flash', {
              message: 'Erro ao criar o pedido',
              type: 'error'
            })
            console.log(error)
          })
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado',
          type: 'error'
        })
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    },
    async updateOrderAddItems (order) {
      this.setLoadingState(true)
      try {
        const newItems = this.order.items.map((item) => ({
          id: item.id,
          quantity: item.quantity,
          unit_original_price: Math.round(item.unit_original_price),
          unit_discount_price: item.unit_amount,
          subtotal: Math.round(item.unit_amount * item.quantity),
          price_id: item.price_id,
          delivery_at: item.delivery_at
        }))

        const formData = new FormData()
        newItems.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            formData.append(`items[${index}][${key}]`, item[key])
          })
        })

        await axios.post(`${process.env.VUE_APP_API_URL}/api/my/orders/${order.id}/items`, formData)

        this.$notification.emit('flash', { message: 'Pedido criado com sucesso!', type: 'success' })
        this.fetchOrders(1)
        this.$emit('close')
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao criar o pedido', type: 'error' })
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    }
  },
  created () {
    this.fetchUser()
    this.fetchItems({ query: '' })
  }

}
</script>
