<template>
    <div class="table-main dash-table dash-hunts">
        <h2>Cashback</h2>
        <div class="table-wrap">
            <div class="balance-discount">
                <div class="bd-block balance">
                    <h3>Saldo disponível</h3>
                    <p v-if="user.attributes">R$ {{ $formatCentsToCurrency(user.attributes.availableBalance) }}</p>
                    <span v-if="this.creditsQty === 1">Ganhos em {{this.creditsQty}} nota</span>
                    <span v-else>Ganhos em {{this.creditsQty}} notas</span>
                </div>
                <div class="bd-block discount">
                    <h3>Desconto atual</h3>
                    <p v-if="user.attributes">{{ user.attributes.discounts.current * 100 }}%OFF</p>
                    <span>Baseado nos descontos que você deu</span>
                </div>
            </div>
            <p class="sub-title" v-if="statements.length > 0">Extrato</p>
            <table>
                <tr v-for="statement in statements" :key="statement.id">
                    <td class="numbers-balance">
                        <p :class="statement.attributes.type === 'debit' || statement.attributes.type === 'debit_admin' ? 'less' : 'added'">
                          <template v-if="statement.attributes.model == 'invoices' && statement.attributes.type === 'debit'">
                            -
                          </template>
                          <template v-if="statement.attributes.model == 'invoices' && statement.attributes.type === 'debit_admin'">
                            -
                          </template>
                          {{ formatNumber(statement.attributes.amount) }}
                        </p>
                    </td>
                    <td>
                      <p v-if="statement.attributes.model == 'invoices'">
                        <template v-if="statement.attributes.type === 'credit'">
                          Upload da nota {{ statement.attributes.value }}
                        </template>
                        <template v-if="statement.attributes.type === 'debit'">
                          Cancelamento da nota {{ statement.attributes.value }}
                        </template>
                        <template v-if="statement.attributes.type === 'debit_admin'">
                          Rejeição da nota {{ statement.attributes.value }}
                        </template>
                      </p>
                      <p v-if="statement.attributes.model == 'compensations'">
                        Compensação. Motivo: {{ statement.attributes.value }}
                      </p>
                      <p v-if="statement.attributes.model == 'orders'">
                        Desconto no pedido #{{ statement.relationships.orders.attributes.id }}
                      </p>
                    </td>
                    <td class="date-time">
                        <p>{{ $formattedDateWithoutHours(statement.attributes.createdAt) }}</p>
                        <p>{{ $formattedDateOnlyHours(statement.attributes.createdAt) }}</p>
                    </td>
                </tr>

            </table>
            <div class="buttons-wrap">
                <button class="main-btn upload-note" @click="openUploadInvoiceModal">
                    <p>Subir nota</p>
                    <div></div>
                </button>
                <router-link class="main-btn grey-btn see-hunts" to="/hunts">
                  Ver tudo
                </router-link>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../config/axiosConfig.js'

export default {
  data () {
    return {
      bigNumbers: '',
      creditsQty: '',
      isUploadModalOpened: false
    }
  },
  computed: {
    ...mapGetters(['user', 'statements'])
  },
  methods: {
    ...mapActions(['fetchStatements', 'setLoadingState', 'setModalUploadInvoiceOpened', 'setNfeItems', 'setModalSetItemRelationshipOpened']),
    openUploadInvoiceModal () {
      this.setNfeItems([])
      this.setModalSetItemRelationshipOpened(false)
      this.setModalUploadInvoiceOpened(true)
    },
    async fetchBigNumbers () {
      this.setLoadingState(true)
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/bignumbers`)
        this.bigNumbers = response.data.data
        this.creditsQty = response.data.data.attributes.creditsQty
      } catch (error) {
        this.$notification.emit('flash', { message: 'Houve um erro ao buscar seus dados', type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    formatNumber (amount) {
      // Converte para número e verifica se é um número válido
      if (!isNaN(amount) && amount !== null && amount !== undefined) {
        // Divide o valor por 100 para representar centavos
        const cents = amount / 100

        // Formata o número como moeda brasileira
        const formattedAmount = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(cents)

        return formattedAmount
      }

      // Retorna o valor original se não for um número válido
      return amount
    }
  },
  created () {
    this.fetchStatements({ page: 1, pageSize: 7 })
    this.fetchBigNumbers()
  }
}
</script>
